import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Camera, camerasByStatusGetAPI } from 'api/cameras';

/**
 * 指定したstatusのデバイス一覧を取得するcustom hooks
 */
export const useCamerasByStatus = (status?: string) => {
  const [cameras_by_status, setCamerasByStatus] = useState<Camera[] | undefined>(undefined);
  const cameras_by_status_record: Record<string, Camera> = useMemo(() => {
    return cameras_by_status ? keyBy(cameras_by_status, (d) => String(d.camera_id)) : {};
  }, [cameras_by_status]);
  const loadCamerasByStatus = useCallback(async () => {
    setCamerasByStatus(undefined);
    const res = await camerasByStatusGetAPI({ status: status });
    if (res.status === 200) {
      setCamerasByStatus(res.data);
    } else {
      setCamerasByStatus([]);
    }
  }, [status]);

  useEffect(() => {
    loadCamerasByStatus();
  }, [loadCamerasByStatus]);

  return useMemo(() => {
    return {
      cameras_by_status,
      cameras_by_status_record,
      setCamerasByStatus,
      loadCamerasByStatus,
    };
  }, [cameras_by_status, cameras_by_status_record, setCamerasByStatus, loadCamerasByStatus]);
};
