import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Map, mapsGetAPI } from 'api/maps';

/**
 * テナントに紐づくマップ一覧を取得するcustom hooks
 */

export const useMapsByTenantId = (tenant_id?: number) => {
  const [maps, setMaps] = useState<Map[] | undefined>(undefined);
  const maps_record: Record<string, Map> = useMemo(() => {
    return maps ? keyBy(maps, (d) => String(d.map_id)) : {};
  }, [maps]);
  const loadMaps = useCallback(async (tenant_id?: number) => {
    setMaps(undefined);
    let new_maps: Map[] = [];
    if (tenant_id) {
      const res = await mapsGetAPI({ tenant_id });
      if (res.status === 200) {
        new_maps = res.data;
      }
    }
    setMaps(new_maps);
  }, []);

  useEffect(() => {
    loadMaps(tenant_id);
  }, [loadMaps, tenant_id]);

  return useMemo(() => {
    return {
      maps,
      maps_record,
      setMaps,
      loadMaps,
    };
  }, [maps, maps_record, setMaps, loadMaps]);
};
