import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Tenants, tenantsGetAPI } from 'api/tenants';

/**
 * テナント一覧を取得するcustom hooks
 */
export const useTenants = () => {
  const [tenants, setTenants] = useState<Tenants[] | undefined>(undefined);
  const tenants_record: Record<string, Tenants> = useMemo(() => {
    return tenants ? keyBy(tenants, (d) => String(d.tenant_id)) : {};
  }, [tenants]);
  const loadTenants = useCallback(async () => {
    setTenants(undefined);
    const res = await tenantsGetAPI();
    if (res.status === 200) {
      setTenants(res.data);
    } else {
      setTenants([]);
    }
  }, []);

  useEffect(() => {
    loadTenants();
  }, [loadTenants]);

  return useMemo(() => {
    return {
      tenants,
      tenants_record,
      setTenants,
      loadTenants,
    };
  }, [tenants, tenants_record, setTenants, loadTenants]);
};
