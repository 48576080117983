import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Map, mapsGetAPI } from 'api/maps';

/**
 * 親マップになるマップ一覧を取得するcustom hooks
 */
export const useParentMaps = () => {
  const [parent_maps, setParentMaps] = useState<Map[] | undefined>(undefined);
  const parent_maps_record: Record<string, Map> = useMemo(() => {
    return parent_maps ? keyBy(parent_maps, (d) => String(d.map_id)) : {};
  }, [parent_maps]);
  const loadparent_maps = useCallback(async () => {
    setParentMaps(undefined);
    const res = await mapsGetAPI({ only_area: true });
    if (res.status === 200) {
      setParentMaps(res.data);
    } else {
      setParentMaps([]);
    }
  }, []);

  useEffect(() => {
    loadparent_maps();
  }, [loadparent_maps]);

  return useMemo(() => {
    return {
      parent_maps,
      parent_maps_record,
      setParentMaps,
      loadparent_maps,
    };
  }, [parent_maps, parent_maps_record, setParentMaps, loadparent_maps]);
};
