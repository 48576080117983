import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from '@shared/functions';
import { Map, mapsGetAPI } from 'api/maps';

/**
 * マップ一覧を取得するcustom hooks
 */
export const useMaps = (only_area: boolean) => {
  const [maps, setMaps] = useState<Map[] | undefined>(undefined);
  const maps_record: Record<string, Map> = useMemo(() => {
    return maps ? keyBy(maps, (d) => String(d.map_id)) : {};
  }, [maps]);
  const loadMaps = useCallback(async () => {
    setMaps(undefined);
    const res = await mapsGetAPI({ only_area: only_area });
    if (res.status === 200) {
      setMaps(res.data);
    } else {
      setMaps([]);
    }
  }, [only_area]);

  useEffect(() => {
    loadMaps();
  }, [loadMaps]);

  return useMemo(() => {
    return {
      maps,
      maps_record,
      setMaps,
      loadMaps,
    };
  }, [maps, maps_record, setMaps, loadMaps]);
};
